<template>
  <div>
    <template v-if="id && valid">
      読み込み中です。。。
    </template>
    <template v-else-if="checkAgain">
      読み込み中にエラーが発生しました。URLを確認してもう一度お試し下さい。
    </template>
    <template v-else>
      不正なURLです。
    </template>
  </div>
</template>
<script>
import {getContractTabsByContractType} from '@/constants/contractTabs'

export default {
  name: 'contract-detail-redirect',
  data() {
    return {
      valid: true,
      checkAgain: false
    }
  },
  async mounted() {
    if (this.id) {
      try {
        const {
          id, clientId, tentative, productTypeId
        } = await this.$store.dispatch('contractInfoForRedirect', this.id)
        const tabs = getContractTabsByContractType(productTypeId, clientId, id, tentative);
        const to = tabs[0];
        if (to) {
          this.$router.replace(to.to)
        } else {
          this.valid = false
        }
      } catch (e) {
        this.valid = false
        this.checkAgain = true
      }
    }
  },
  computed: {
    id () {
      return parseInt(this.$route.params.id, 10)
    }
  }
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.header-main {
  background-color: #f5f5f5 !important;
  .btn-crm-primary {
    border: 0.5px solid #1873d9;
  }
}
.btn-cus {
  height: 32px !important;
  ::v-deep {
    .v-btn__content {
      color: #1873D9 !important;
      font-weight: 600 !important;
      font-size: 12px !important;
    }
  }
}
</style>
